.App {
    /* width: 100vw; */
    /* min-height: 100vh;  */
    height: 100vh;
    background-image: url('rtwbg.jpg');
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    overflow-y: auto;
}

.centered-box {
    position: fixed; /* Fixiert die Position relativ zum Viewport */
    top: 50%; /* Vertikale Mitte */
    left: 50%; /* Horizontale Mitte */
    transform: translate(-50%, -50%); /* Verschiebt das Element um die Hälfte seiner eigenen Breite und Höhe zurück */
    background-color: white; /* Hintergrundfarbe */
    padding: 20px; /* Innenabstand */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Schatten für bessere Sichtbarkeit */
    border-radius: 8px; /* Abgerundete Ecken */
    z-index: 9999; /* Stellt sicher, dass die Box über anderen Elementen liegt */
  }

.inhalt {
    margin-top: 80px;
}

.main {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px) contrast(0.3) brightness(1.8); 
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* margin-top: 70px; */
    /*align-items: stretch;*/
}

.mainblank {
    background-color: rgba(255, 255, 255, 0.3) !important;
    backdrop-filter: blur(10px) contrast(0.3) brightness(1.8); 
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* margin-top: 70px; */
    /*align-items: stretch;*/
}

.item {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 5px;
    background-color: unset;
    justify-content: space-between;
    height: 280px;
}

.item_mobile {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 5px;
    background-color: unset;
    justify-content: space-between;
}

.img_browser {
    max-height: 200px;
    width: auto;
}

.img_mobile {
    max-height: 250px;
    width: auto;
}

.bestellbuttonmitpreis {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-direction: row; 
    font-size: 80%;
    align-items: flex-end;
}
.preis {
    font-size: 140%;
}

.budgetgrey {
    color:#bcbcbc;
}

.budgetwhite {
    color:white;
}